import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import backgroundImg from "../assets/images/mitten-bg.jpg"
import mobileBackgroundImg from "../assets/images/mitten-bg-mobile.jpg"
// import backgroundImg from "../assets/images/main-banner.webp"

const useStyles = makeStyles((theme) => ({
  background: {
    marginTop: "-7rem",
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      backgroundImage: `url(${mobileBackgroundImg})`,
    },
  },
  contentCtr: {
    padding: "13rem 10rem",
    [theme.breakpoints.down("lg")]: {
      padding: "11rem 10rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "11rem 2rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3rem 2rem",
      marginBottom: "3rem",
    },
  },
  h1: {
    fontSize: "1.475rem",
    color: "#e9e7e7",
    padding: "1rem 0",
    position: "relative",
    lineHeight: 1.25,
    letterSpacing: "-.9px",
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      backgroundColor: "#fff",
      width: 65,
      height: 4,
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      backgroundColor: "#fff",
      width: 65,
      height: 4,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.375rem",
    },
  },
  h2: {
    padding: "1.25rem 0",
    fontSize: "3.25rem",
    color: "#e9e7e7",
    lineHeight: 1.05,
    letterSpacing: "-.9px",
    fontWeight: 800,
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.25rem",
      lineHeight: 1.15,
    },
  },
  embolden: {
    color: "#14e3a1",
  },
  emboldenBorder: {
    // borderBottom: `2px solid #14e3a1`,
    position: "relative",
    lineHeight: 1.15,
    "&::after": {
      content: "''",
      position: "absolute",
      borderBottom: `2px solid #14e3a1`,
      top: "-15px",
      left: "0px",
      right: "4px",
      bottom: "5px",
    },
  },
  body: {
    color: "#e9e7e7",
    fontSize: "1.05rem",
  },
}))

export default function HomePageHero() {
  const classes = useStyles()

  return (
    <>
      <section className={classes.background}>
        <Grid container className={classes.contentCtr}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <img
                src={backgroundImg}
                style={{ display: "none" }}
                alt="increase priority"
              />
              <Grid item xs={12}>
                <Typography variant="h1" className={classes.h1}>
                  A Michigan <br />
                  Web Development <br />
                  and Marketing Company
                </Typography>
              </Grid>
              {/* <Grid item xs={12}>
                <Typography variant="h2" className={classes.h2}>
                  <span className={classes.embolden}>Designing</span>
                  <br />
                  Goal-Oriented,
                  <br />
                  <span className={classes.embolden}>Responsive</span> Websites
                </Typography>
              </Grid> */}
              <Grid item xs={12}>
                <Typography variant="h2" className={classes.h2}>
                  <span className={classes.embolden}>Build</span> your
                  <br />
                  <span className={classes.emboldenBorder}>Fastest</span>, most
                  <br />
                  <span className={classes.embolden}>Profitable</span> Website
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container item xs={12} sm={10} md={10} lg={8} xl={6}>
                  <Typography
                    variant="body1"
                    paragraph
                    className={classes.body}
                    style={{marginBottom: '2rem'}}
                  >
                    Rival your biggest competitors at a <br />fraction of the cost. <br />
                    <span className={classes.embolden} style={{fontWeight: 500,}}>Headache free.</span> Guaranteed.
                  </Typography>
                  {/* <Typography
                    variant="body1"
                    paragraph
                    className={classes.body}
                  >
                    To empower small business owners with cost efficient digital
                    solutions that rival the quality of middle/high-market
                    companies in aesthetics and results.
                  </Typography> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </section>
    </>
  )
}
