import React from "react"
import loadable from "@loadable/component"
// import styled from "styled-components"

import { Container } from "reactstrap"

import Grid from "@material-ui/core/Grid"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Skeleton from "@material-ui/lab/Skeleton"
import MuiBox from "@material-ui/core/Box"
import HomePageHero from "../components/homePageHero"
import QuoteForm from '../components/QuoteForm'

const GrayscaleTechnologies = loadable(
  () => import("../components/grayscaleTechnologies"),
  {
    fallback: (
      <div>
        <Skeleton
          variant="text"
          width={610}
          animation="wave"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "-.2125rem",
            marginBottom: ".9rem",
          }}
        />
        <Grid
          container
          wrap="nowrap"
          justify="space-around"
          style={{ marginBottom: "5rem" }}
        >
          {Array.from(new Array(5)).map((item, index) => {
            return (
              <MuiBox key={index} width={60} mx={8} my={5}>
                <Skeleton variant="circle" width={50} height={50} />
              </MuiBox>
            )
          })}
        </Grid>
      </div>
    ),
  }
)
const FeaturedWorkCTA = loadable(() => import("../components/FeaturedWorkCTA"))
// const Benefits = loadable(() => import("../components/benefits"))
const ServiceCards = loadable(() => import("../components/servicesCards"))
// const QuoteForm = loadable(() => import("../components/QuoteForm"))

// let StyledBackground = styled.div`
//   /* background: linear-gradient(to bottom, #f9fbfd 0, #fff 100%); */
//   background: rgb(249, 251, 253);
//   background: linear-gradient(
//     0deg,
//     rgba(249, 251, 253, 1) 0%,
//     rgba(249, 248, 248, 1) 90%,
//     rgba(255, 255, 255, 1) 100%
//   );
// `

let Index = () => (
  <Layout dark={true}>
    <Seo title="We are MI Web Designers" />
    <HomePageHero />
    <GrayscaleTechnologies header={false} />
    
    <Container className="pt-5">
      <div className="text-center pt-2">
        <h4 style={{ color: "#000", fontWeight: 600 }}>
          Snappy Web Design is the one-stop-shop for your online presence.
        </h4>
        {/* <p className="text-muted">
          We leverage our expertise in marketing, finance, and computer science
          to provide unmatchable SEO & functionality.
        </p> */}
        <p className="text-muted">
          Work and stress less.
          Reach & retain more customers.
        </p>
      </div>
    </Container>
    <ServiceCards />
    <FeaturedWorkCTA />

    {/* <StyledBackground>
      <Benefits />
    </StyledBackground> */}
    <QuoteForm />
  </Layout>
)

export default Index
