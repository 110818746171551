import React from "react"
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import useMediaQuery from "@material-ui/core/useMediaQuery"

// import QuoteBg from "../assets/images/gradient-bg-contact.png"
import QuoteBg from "../assets/images/blob-bg-contact.svg"

const useStyles = makeStyles((theme) => ({
  quoteSection: {
    backgroundImage: `url(${QuoteBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",
    padding: "5.6rem 1rem 5.25rem",
  },
  textGrid: {
    marginLeft: "3rem",
    paddingRight: "6rem",
    position: "relative",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: ".75rem",
      paddingRight: "3rem",
    },
  },
  quoteHeading: {
    fontFamily: "Manrope, Helvetica, sans serif",
    fontSize: "2.8rem",
    fontWeight: 300,
    textTransform: "capitalize",
    color: "#fff",
  },
  quoteBody: {
    fontFamily: "Manrope, Helvetica, sans serif",
    fontSize: "1.25rem",
    fontWeight: 300,
    color: "#fff",
  },
  formGrid: {
    padding: "0rem 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0rem .5rem",
    },
  },
  form: {
    padding: 4,
  },
  scheduleBtn: {
    marginTop: "1rem",
    borderRadius: 0,
    textTransform: "uppercase",
    color: "rgba(0,0,0,.9)",
    padding: ".75rem 3rem",
    fontWeight: 500,
    fontSize: "1rem",
    backgroundColor: "#14e3a1",
    border: '2px solid transparent',
    transition: "color .3s, background-color .3s",
    transitionTimingFunction: "ease-in-out",
    "&:hover": {
      backgroundColor: "#000",
      color: "#14e3a1",
      border: "2px inset #14e3a1",
    },
  },
}))

const CssTextField = withStyles({
  root: {
    marginTop: ".25rem",
    marginBottom: ".25rem",
    width: "100%",
    "& .MuiInputBase-root": {
      color: "#fff",
    },
    "& label": {
      color: "#fff",
    },
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "rgba(0,0,0,.34)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  },
})(TextField)

export default function QuoteForm() {
  const theme = useTheme()
  const classes = useStyles()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <>
      <section className={classes.quoteSection}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Grid container alignItems="center" className={classes.textGrid}>
                <Grid item xs={12} md={10}>
                  <Typography
                    variant="h4"
                    paragraph
                    className={classes.quoteHeading}
                  >
                    Request a Quote
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className={classes.quoteBody}
                  >
                    Let's have a quick 15-min convo to discuss your project
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container justify="center" className={classes.formGrid}>
                <form
                  name="QuoteForm"
                  method="POST"
                  netlify
                  data-netlify="true"
                  className={classes.form}
                >
                  <input type="hidden" name="form-name" value="QuoteForm" />
                  <Grid container justify="center" spacing={1}>
                    <Grid item xs={12} md={6}>
                      <CssTextField
                        label="Name"
                        id="name"
                        name="name"
                        variant="filled"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CssTextField
                        label="Company"
                        id="company"
                        name="company"
                        variant="filled"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CssTextField
                        label="Email Address"
                        id="email"
                        name="email"
                        variant="filled"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CssTextField
                        label="Phone Number"
                        id="phone"
                        name="phone"
                        variant="filled"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CssTextField
                        label="Your Message"
                        id="message"
                        name="message"
                        variant="filled"
                        required
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid item xs={12} container justify="flex-end">
                      <Button
                        variant="contained"
                        className={classes.scheduleBtn}
                        type="submit"
                        fullWidth={matchesXS ? true : false}
                      >
                        Schedule
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  )
}
